<template>
    <div>
        <div class="team-banner"></div>
        <div class="team-nav">
            <ul>
                <li><a @click="changeHash('#team-introduce')">企业简介</a></li>
                <li><a @click="changeHash('#team-culture')">品牌文化</a></li>
                <li><a @click="changeHash('#team-contact')">联系我们</a></li>
                <li><a @click="changeHash('#team-settlement')">商家入驻</a></li>
            </ul>
        </div>
        <div id="team-introduce">
            <div class="anchor-title">
                <h6>企业简介<span>/Introduce</span></h6>
            </div>
            <div class="team-introduce-cont">
                <figure>
                    <div>
                        <span>老马爱家</span>
                        <i>共创美好生活</i>
                    </div>
                </figure>
                <figcaption>
                    <p>
                        老马爱家是中国西部家政产业园与合思迪雅科技有限公司、四川省服务业促进联合推出的全国性家政服务线上O2O平台，其目的是为广大中国家庭提供用心、贴心、暖心的家政服务，提升我国家政服务品牌化、信息化、专业化、规范化水平，同时能够稳就业，增税收，促进家政行业蓬勃发展。老马爱家平台立足互联网+生活服务的新模式，通过大数据链接各大市场主体，做到家政消费雇主、家政企业、从业人员、培训机构、鉴定机构之间的数据互联，为供需双方提供及时供需信息；通过人力资源服务的人力外包服务、劳务派遣服务、招聘就业服务等，降低家政企业用工风险，促进人才有效流通，有效执行企业员工制；为广大培训机构和从业人员建立线上培训系统与线下培训基地，从线下集中培训、线上视频课程培训、上岗师傅带教、周期性考试等四个方面，创建全面的培训系统，全面提高从业人员素质和服务水平，全方位提升家政服务的便捷性、高效性与安全性。
                    </p>

                </figcaption>
            </div>
        </div>
        <div id="team-culture">
            <div class="anchor-title">
                <h6>品牌文化<span>/Brand culture</span></h6>
            </div>
            <div class="team-culture-cont">
                <div class="culture-first">
                    <figcaption>
                        <h6>公司理念</h6>
                        <span>用心服务好每一个客户</span>
                    </figcaption>
                    <figure></figure>
                </div>
                <div class="culture-second">
                    <figcaption>
                        <h6>公司理念</h6>
                        <span>享受品质生活，关爱每一个家庭健康</span>
                    </figcaption>
                    <figure></figure>
                </div>
            </div>
        </div>
        <div id="team-contact">
            <div class="anchor-title">
                <h6>联系我们<span>/Contact information</span></h6>
            </div>
            <div class="team-contact-cont">
                <figcaption>
                    <ul>
                        <li class="contact-first">
                            <article>服务电话</article>
                            <span>400-6046-400</span>
                        </li>
                        <li class="contact-second">
                            <article>邮箱</article>
                            <span>tiger@maygin.com</span>
                        </li>
                        <li class="contact-third">
                            <article>地址</article>
                            <span>成都市青羊区清江中路20号</span>
                        </li>
                    </ul>
                </figcaption>
                <figure></figure>
            </div>
        </div>
        <div id="team-settlement">
            <div class="anchor-title">
                <h6>机构入驻<span>/Business settlement</span></h6>
            </div>
            <div class="team-settlement-cont">
                <div class="settlement-first">
                    <div class="settlement-first-left">
                        <figure></figure>
                        <figcaption>
                            <h6>45%</h6>
                            <span>合作商家增长</span>
                            <span>2019年</span>
                        </figcaption>
                    </div>
                    <div class="settlement-first-right">
                        <figure></figure>
                        <figcaption>
                            <h6>超过5000家</h6>
                            <span>合作资方</span>
                            <span></span>
                        </figcaption>
                    </div>
                </div>
                <div class="settlement-second">
                    <h5>我要入驻</h5>
                    <p>
                        为老马爱家会员提供专业培训、指导。提供日常保洁、开荒保洁、钟点工、御厨到家、搬家、甲醛检测治理、月嫂、催乳师、育儿嫂、保姆等家政服务培训。帮助入驻商家管理服务，快速接单派单。
                    </p>
                    <div style="display: flex; justify-content: space-between">
                        <a href="https://b.laomaaijia.com/shopadmin/register?id=0" target="_black">商家入驻</a>
                        <a href="https://b.laomaaijia.com/shopadmin/register?id=1" target="_black">培训机构入驻</a>
                        <a href="https://b.laomaaijia.com/shopadmin/register?id=2" target="_black">评价机构入驻</a>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="team-process">
            <div class="title">
                <h3>入驻流程</h3>
                <i></i>
                <span>SETTLEMENT PROCESS</span>
            </div>
            <div class="team-process-contone"></div>
            <div class="team-process-contwo">
                <h4>入驻须知</h4>
                <i></i>
                <article>
                    <h5>01</h5>
                    <h6>入驻申请</h6>
                    <span>了解基本需求</span>
                    <p>
                        1.入驻机构主体：要求为以企业名义注册的实体公司，并且能够提供老马爱家入驻要求的相关文件（<em>营业执照、法定代表人身份证件</em> 等...)，暂不支持个体商户入驻。如有疑问可联系<em>400-6046-400</em>  咨询。
                        <br>
                        2.入驻机构主体必须具有家政行业相关的服务资质，否则无法通过审核。
                    </p>
                    <span>准备入驻资料</span>
                    <p>
                        1.选择您的机构所在地、准备好相应资质文件，通信信息，机构logo等在入驻申请页面进行上传提交。
                        <br>
                        2.如入驻资料缺少或有误，会退回给您重新提交，建议您事先根据要求准备齐全，一次性通过审核。
                    </p>
                    <!-- <span>提交入驻申请</span>
                    <p>
                        根据入驻选择填写信息，提交企业资质、行业许可资质，如有品牌信息请完成品牌入库并提交品牌资质，提交成功等待审核，点此 <em>查看流程</em> 。
                    </p> -->

                    <h5>02</h5>
                    <h6>申请审核</h6>
                    <span>资质审核</span>
                    <p>
                        1.所有资质均真实有效且正规，勿采用电子章；
                    </p>
                    <p>
                        2.授权链路完整且有效。
                    </p>

                    <h5>03</h5>
                    <h6>完善机构信息</h6>
                    <span></span>
                    <p>
                        1.资料提交成功，我们会将您登录的账号、密码发送到您填写的唯一手机号上，请根据您注册的机构类型在网站顶部选择菜单 》机构入驻 》 商家/培训机构登录/评价机构登录 后，进入相应机构的登录界面，登录系统后完善其他的资料，添加自己的服务人员。 
                    </p>

                    <h5>04</h5>
                    <h6>机构上线</h6>
                    <span></span>
                    <p>
                        完成其他资料的录入，添加完毕服务人员，待服务人员完成入驻审核，即完成全部上线流程，等待雇主订单的推送。
                    </p>
                </article>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods:{
        changeHash(idname) {
            document.querySelector(idname).scrollIntoView(true);
        }
    }
}
</script>

<style lang="less" scoped>
    @import '../../style/team.less';
</style>